<template>
  <footer
    id="footer"
    class="relative text-base leading-snug text-white bg-neutral-900"
  >
    <!-- <ContactHoverButton /> -->
    <div
      class="relative flex flex-col w-auto h-full px-0 pt-12 pb-8 mx-4 my-0 xl:max-w-6xl xl:mx-auto"
    >
      <div class="w-full mb-8">
        <router-link to="/">
          <img src="~@/assets/RMRGreen_Logo_White.png" class="mx-auto h-16" />
        </router-link>
      </div>
      <div
        class="flex flex-col flex-wrap justify-around align-top md:flex-row md:justify-between"
      >
        <div
          class="flex flex-col justify-around flex-grow sm:flex-row lg:justify-between lg:mr-40"
        >
          <div class="mb-8 text-center sm:text-left sm:ml-4 sm:w-36">
            <h3 class="mt-0 font-semibold">PARTICIPANTS</h3>
            <router-link
              class="block mx-0 my-4 hover:text-primary-light"
              to="/login"
              >Participant Login</router-link
            >
            <router-link
              class="block mx-0 my-4 hover:text-primary-light"
              to="/services/cobra"
              >COBRA</router-link
            >
            <router-link
              class="block mx-0 my-4 hover:text-primary-light"
              to="/forms"
              >Forms</router-link
            >
            <router-link
              class="block mx-0 my-4 hover:text-primary-light"
              to="/whats-covered"
              >What's Covered</router-link
            >
            <router-link
              class="block mx-0 my-4 hover:text-primary-light"
              to="/support"
              >Support & FAQ</router-link
            >
            <router-link
              class="block mx-0 my-4 hover:text-primary-light"
              to="/services"
              >Our Services</router-link
            >
            <router-link
              class="block mx-0 my-4 hover:text-primary-light"
              to="/calculators"
              >Calculators</router-link
            >
            <router-link
              class="block mx-0 my-4 hover:text-primary-light"
              to="/how-to-videos"
              >How to Videos</router-link
            >
          </div>
          <div class="mb-8 text-center sm:text-left sm:ml-4 sm:w-40">
            <h3 class="mt-0 font-semibold">EMPLOYERS / BROKERS</h3>
            <router-link
              class="block mx-0 my-4 hover:text-primary-light"
              to="/login/employer"
              >Employer Login</router-link
            >
            <router-link
              class="block mx-0 my-4 hover:text-primary-light"
              to="/employer-resources"
              >Employer Resources</router-link
            >
            <router-link
              class="block mx-0 my-4 hover:text-primary-light"
              to="/services"
              >Our Services</router-link
            >
            <router-link
              class="block mx-0 my-4 hover:text-primary-light"
              to="/employer-training"
              >Employer Training</router-link
            >
            <router-link
              class="block mx-0 my-4 hover:text-primary-light"
              to="/plan-videos"
              >Plan Videos</router-link
            >
          </div>
          <div
            class="flex flex-col justify-start mb-8 text-center sm:text-left sm:ml-4 sm:w-40"
          >
            <div>
              <h3 class="mt-0 font-semibold">COMPANY</h3>
              <router-link
                class="block mx-0 my-4 hover:text-primary-light"
                to="/about"
                >About Us</router-link
              >
              <router-link
                class="block mx-0 my-4 hover:text-primary-light"
                to="/contact"
                >Contact Us</router-link
              >
              <a
                href="https://rockymountainreserve.applytojob.com/apply"
                class="block mx-0 my-4 hover:text-primary-light"
              >
                Careers
              </a>
              <a
                href="https://trust.rmrbenefits.com/"
                class="block mx-0 my-4 hover:text-primary-light"
              >
                Trust Center
              </a>
            </div>
            <div class="mt-6">
              <h3 class="mt-0 font-semibold">LEGAL</h3>
              <router-link
                class="block mx-0 my-4 hover:text-primary-light"
                to="/privacy"
                >Privacy</router-link
              >
              <router-link
                class="block mx-0 my-4 hover:text-primary-light"
                to="/terms-of-use"
                >Terms of Use</router-link
              >
            </div>
          </div>
        </div>
        <div
          class="relative flex flex-col items-center order-first mb-16 md:order-none md:mb-8 md:w-60"
        >
          <Button
            reg
            large-icons
            slide-text-right="888.722.1223"
            trailing="flaticon-phone"
            href="tel:8887221223"
            class="mb-4 w-72 md:w-52"
          >
            Give Us A Call
          </Button>

          <Button
            reg
            large-icons
            trailing="flaticon-email"
            slide-text-right="info@rmrbenefits.com"
            href="mailto:info@rmrbenefits.com"
            tooltip-click="Email Copied!"
            @click-button="setClipboard('info@rmrbenefits.com')"
            class="mb-4 w-72 md:w-52"
          >
            Send Us An Email
          </Button>

          <!-- <Button
            reg
            large-icons
            trailing="flaticon-question"
            @click-button="openChat()"
            class="mb-4 w-72 md:w-52"
          >
            Chat Live With Us
          </Button> -->
          <Button
            reg
            large-icons
            trailing="flaticon-question"
            onclick="zE('messenger', 'open')"
            class="mb-4 w-72 md:w-52"
          >
            Chat Live With Us
          </Button>
          <div class="w-full mx-0 mt-2 text-center">
            <a href="https://www.facebook.com/RMRBenefits/">
              <div id="facebook" class="inline-block w-10 h-10 mx-3 my-0" />
            </a>
            <a href="https://twitter.com/rmrbenefits">
              <div id="twitter" class="inline-block w-10 h-10 mx-3 my-0" />
            </a>
            <a
              href="https://www.linkedin.com/company/rocky-mountain-reserve?trk=tyah&trkInfo=clickedVertical%3Acompany%2CentityType%3AentityHistoryName%2CclickedEntityId%3Acompany_514535%2Cidx%3A0"
            >
              <div id="linkedin" class="inline-block w-10 h-10 mx-3 my-0" />
            </a>
          </div>
        </div>
      </div>
      <p class="w-full text-center copyright">
        Copyright © 2021 Rocky Mountain Reserve
      </p>
    </div>
  </footer>
</template>

<script>
// import ContactHoverButton from "@/components/global/footer/components/ContactHoverButton.vue";
import Button from "@/components/ui/Button.vue";

export default {
  components: {
    // ContactHoverButton,
    Button
  },
  mounted() {
    this.$zendesk.load("a95486a9-0bcf-4bda-99ac-f81566ab1958");
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    // handleScroll() {
    //   let footer = document.getElementById("footer");
    //   let button = document.getElementById("contact-hover-button");
    //   let width = window.matchMedia("(min-width: 550px)");

    //   let footerRect = footer.getBoundingClientRect();
    //   let heightOfFooter = window.innerHeight - footerRect.top;

    //   if (heightOfFooter > 200 || !width.matches) {
    //     button.style.opacity = "0";
    //     setTimeout(() => {
    //       if (button.style.opacity === "0") {
    //         button.style.visibility = "hidden";
    //       }
    //     }, 250);
    //   } else {
    //     button.style.visibility = "visible";
    //     button.style.opacity = "1";
    //   }
    // },
    setClipboard(value) {
      var tempInput = document.createElement("input");
      tempInput.style = "position: absolute; left: -1000px; top: -1000px";
      tempInput.value = value;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
    }
    // openChat() {
    //   var el = this;
    //   this.$zendesk.show();
    //   this.$zendesk.open();
    //   this.$zendesk.zE("webWidget:on", "close", () => {
    //     el.$zendesk.hide();
    //   });
    // }
  }
};
</script>

<style scoped>
#linkedin {
  background: url("~@/assets/socialMedia/LinkedIn-White.png");
  background-size: contain;
}

#linkedin:hover {
  background: url("~@/assets/socialMedia/LinkedIn-Color.png");
  background-size: contain;
}

#twitter {
  background: url("~@/assets/socialMedia/Twitter-White.png");
  background-size: contain;
}

#twitter:hover {
  background: url("~@/assets/socialMedia/Twitter-Color.png");
  background-size: contain;
}

#facebook {
  background: url("~@/assets/socialMedia/Facebook-White.png");
  background-size: contain;
}

#facebook:hover {
  background: url("~@/assets/socialMedia/Facebook-Color.png");
  background-size: contain;
}
</style>

<!-- uncomment Line 6, 178, 183, handlescroll, and openChat -->
