<template>
  <header id="navbar" class="relative z-50">
    <Topnav :isMobile="isMobile" />
    <Sidenav :isMobile="isMobile" />
  </header>
</template>

<script>
import Topnav from "./components/topnav/Topnav.vue";
import Sidenav from "./components/sidenav/Sidenav.vue";

export default {
  components: {
    Topnav,
    Sidenav
  },
  data() {
    return {
      windowWidth: window.innerWidth
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  computed: {
    isMobile: function() {
      return this.windowWidth < 992;
    }
  }
};
</script>
