<template>
  <div
    id="plan-limits-banner"
    v-if="showAlertBar"
    class="relative text-white flex  w-full-8 text-center py-4 px-6 z-10 bg-gradient-to-r from-primary-800 via-primary-700 to-primary-800 shadow"
  >
    <!-- <a
      href="https://rmr.zendesk.com/hc/en-us/community/topics/360001268633-Industry-Regulation-Updates"
      class="self-center flex-1"
    >
      <span class="">
        On November 10, 2021, the IRS released the cost-of-living increases for
        inflation for the coming year. Click here to find out the 2022 plan
        limits.
      </span>
      The IRS has increased contribution limits for 2023.
      </span>
    </a> -->
    <!-- <p class="self-center flex-1">
      For 2023 the IRS has increased the following contribution limits:
      <router-link to="/services/hsa" class="inline-link">HSA</router-link>,
      <router-link to="/services/fsa" class="inline-link"> FSA</router-link>,
      <router-link to="/services/dca" class="inline-link"> DCAP</router-link>,
      and
      <router-link to="/services/transit" class="inline-link">
        Transit/Parking</router-link
      >.
    </p> -->
    <!-- <p class="self-center flex-1">
      The End of the National Emergency is May 11, 2023 (
      <a
        href="https://rmr.zendesk.com/hc/en-us/community/posts/13841007447827-The-End-of-the-National-Emergency-is-May-11-2023"
        class="self-center flex-1 inline-link"
        >See details</a
      >
      )
    </p> -->
    <p class="self-center flex-1" @click="handleClick">
      <router-link
        to="/support/articles/360042660754"
        class="self-center flex-1 inline-link"
      >
        IRS Releases 2025 Limits for HSAs</router-link
      >
    </p>
    <span
      class="flaticon-cancel self-center right-12 top-0 ml-4 text-sm font-bold text-primary-light cursor-pointer"
      @click="handleClick"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      showAlertBar: true
    };
  },
  methods: {
    handleClick() {
      this.$emit("alert-bar-closed");
      var element = document.getElementById("plan-limits-banner");
      element.style.height = getComputedStyle(element).height;

      setTimeout(() => {
        element.style.transition = `all .5s ease`;
        element.style.padding = "0 1.5rem";
        element.style.height = 0;
      });

      setTimeout(() => {
        this.showAlertBar = false;
      }, 500);
    }
  }
};
</script>
