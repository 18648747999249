import Vue from "vue";

const state = {
  zendeskData: [],
  categories: [
    {
      id: 360002944813,
      name: "Participants/Employees",
      routeName: "participants"
    },
    { id: 360002944833, name: "Employers", routeName: "employers" }
  ]
};

const getters = {
  allZendeskData(state) {
    return state.zendeskData;
  },
  categoryById: state => id => {
    if (state.zendeskData && state.zendeskData.categories) {
      return state.zendeskData.categories.find(category => category.id === id);
    } else {
      return null;
    }
  },
  sectionsByCategoryId: state => id => {
    if (state.zendeskData && state.zendeskData.sections) {
      return state.zendeskData.sections.filter(
        section => section.category_id === id
      );
    } else {
      return null;
    }
  },
  sectionById: state => id => {
    if (state.zendeskData && state.zendeskData.sections) {
      return state.zendeskData.sections.find(section => section.id === id);
    } else {
      return null;
    }
  },

  articleById: state => id => {
    if (state.zendeskData === undefined) {
      return null;
    } else if (state.zendeskData.length == 0) {
      return null;
    } else if (state.zendeskData.articles === undefined) {
      return null;
    } else if (state.zendeskData.articles.length === 0) {
      return null;
    } else {
      var article = state.zendeskData.articles.find(
        article => article.id == id
      );
      return article;
    }
  },
  routeNameBySectionId: state => id => {
    if (state.zendeskData === undefined) {
      return null;
    } else if (state.zendeskData.length == 0) {
      return null;
    } else if (state.zendeskData.sections === undefined) {
      return null;
    } else if (state.zendeskData.sections.length === 0) {
      return null;
    } else {
      var categoryId = state.zendeskData.sections.find(
        section => section.id === id
      ).category_id;
      var routeName = state.categories.find(
        category => category.id === categoryId
      ).routeName;
      return routeName;
    }
  }
};

const actions = {
  async fetchZendeskData({ commit, state }) {
    if (state.zendeskData === undefined || state.zendeskData.length === 0) {
      // console.log("Fetching Zendesk Data...");
      let url =
        "https://rmr.zendesk.com/api/v2/help_center/en-us/articles.json?per_page=100&include=categories,sections&sort_by=title&sort_order=asc";
      var allData = null;

      while (url !== null) {
        await fetch(url)
          .then(response => response.json())
          .then(data => {
            if (!allData) {
              allData = data;
            } else {
              allData.articles = allData.articles.concat(data.articles);
            }
            url = data.next_page;
          });
      }

      commit("setZendeskData", allData);
      commit("sortZendeskData");
    }
  }
};

const mutations = {
  setZendeskData: (state, zendeskData) => {
    state.zendeskData = zendeskData;
  },
  sortZendeskData: state => {
    state.zendeskData.sections.forEach(section => {
      Vue.set(section, "articles", []);
    });

    state.zendeskData.articles.forEach(article => {
      let sectionIndex = state.zendeskData.sections.findIndex(
        section => section.id === article.section_id
      );

      if (sectionIndex >= 0) {
        state.zendeskData.sections[sectionIndex].articles.push(article);
      }
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
