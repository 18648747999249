<template>
  <div
    id="topnav"
    class="flex flex-row justify-center h-20 px-12 py-0 text-base transition-all duration-200 bg-neutral-light shadow xl:px-32"
  >
    <router-link to="/">
      <img
        id="topnav-logo"
        src="@/assets/RMRGreen_Logo.png"
        alt="RMR Logo"
        draggable="false"
        class="inline-block mx-auto my-2 h-16"
      />
    </router-link>
    <div
      id="topnav-links"
      v-show="!isMobile"
      class="inline-block m=0 py-0 mx-4"
    >
      <router-link
        to="/"
        class="inline-block p-4 mx-0 my-4 leading-4 transition-all rounded hover:bg-neutral active:bg-neutral-dark"
      >
        Home
      </router-link>
      <Dropdown heading="Participants" class="z-10">
        <router-link
          class="block p-4 transition-all hover:bg-neutral active:bg-neutral-dark"
          to="/login/participant"
        >
          Participant Login
        </router-link>
        <router-link
          class="block p-4 transition-all hover:bg-neutral active:bg-neutral-dark"
          to="/services/cobra"
        >
          COBRA
        </router-link>
        <router-link
          class="block p-4 transition-all hover:bg-neutral active:bg-neutral-dark"
          to="/forms"
        >
          Forms
        </router-link>
        <router-link
          class="block p-4 transition-all hover:bg-neutral active:bg-neutral-dark"
          to="/whats-covered"
        >
          What's Covered
        </router-link>
        <router-link
          class="block p-4 transition-all hover:bg-neutral active:bg-neutral-dark"
          to="/support"
        >
          Support & FAQ
        </router-link>
        <router-link
          class="block p-4 transition-all hover:bg-neutral active:bg-neutral-dark"
          to="/services"
        >
          Our Services
        </router-link>
        <router-link
          class="block p-4 transition-all hover:bg-neutral active:bg-neutral-dark"
          to="/calculators"
        >
          Calculators
        </router-link>
        <a
          class="block p-4 transition-all hover:bg-neutral active:bg-neutral-dark"
          href="https://fsastore.com/rockymountainreserve.html?AFID=490026&GroupName=TPA&CID=437559&utm_source=Rocky+Mountain+Reserve&utm_medium=tpa+public+link+nav+CLP&utm_campaign=TPA+Partner"
        >
          FSA Store
        </a>
        <!-- <a
          class="block p-4 transition-all hover:bg-neutral active:bg-neutral-dark"
          href="https://rmr.directfsa.com/"
        >
          <img src="@/assets/Direct FSA Logo.png" alt="Direct FSA Logo" />
        </a> -->
      </Dropdown>
      <Dropdown heading="Employers/Brokers">
        <router-link
          class="block p-4 transition-all hover:bg-neutral active:bg-neutral-dark"
          to="/login/employer"
        >
          Employer Login
        </router-link>
        <router-link
          class="block p-4 transition-all hover:bg-neutral active:bg-neutral-dark"
          to="/employer-resources/open-enrollment"
        >
          Employer Resources
        </router-link>
        <router-link
          class="block p-4 transition-all hover:bg-neutral active:bg-neutral-dark"
          to="/services"
        >
          Our Services
        </router-link>
        <router-link
          class="block p-4 transition-all hover:bg-neutral active:bg-neutral-dark"
          to="/support/categories/360002944833"
        >
          Support & FAQ
        </router-link>
        <router-link
          class="block p-4 transition-all hover:bg-neutral active:bg-neutral-dark"
          to="/employer-training"
        >
          Employer Training
        </router-link>
      </Dropdown>
    </div>
    <div
      id="topnav-search"
      v-show="!isMobile"
      class="relative flex-1 px-4 py-0"
    >
      <div
        id="searchbar"
        class="relative float-right mx-0 my-6 overflow-hidden bg-white border border-neutral-dark border-solid rounded focus-within:border-primary-light"
      >
        <span
          class="absolute inline-block mx-2 my-0 font-bold leading-8 text-neutral-dark flaticon-search-1"
          :class="{ active: searchValue !== '' }"
        />
        <input
          type="text"
          placeholder="Search"
          v-model="searchValue"
          class="w-full h-8 px-8 py-2 font-sans text-base leading-4 border-none outline-none"
        />
        <transition name="fadeIn">
          <span
            @click="searchValue = ''"
            v-show="searchValue"
            class="absolute top-0 right-0 inline-block mx-2 my-0 text-xs font-bold leading-8 text-neutral-dark transition-opacity cursor-pointer flaticon-cancel"
          />
        </transition>
        <div id="results" v-if="searchValue !== ''">
          <router-link
            v-for="(result, index) in results.slice(0, 5)"
            :key="index"
            :to="result.path"
            class="block p-2 border-0 border-neutral-dark border-solid hover:bg-neutral-light active:bg-neutral first:border-t"
            @click.native="searchValue = ''"
          >
            Go to {{ result.name }}
          </router-link>
          <router-link
            :to="'/support/search/' + searchValue"
            class="block p-2 border-0 border-t border-neutral-dark border-solid hover:bg-neutral-light active:bg-neutral"
            @click.native="searchValue = ''"
          >
            Search articles for
            <u
              ><i>{{ searchValue }}</i></u
            >
          </router-link>
        </div>
      </div>
    </div>
    <div id="topnav-login" v-show="!isMobile" class="mx-0 my-4">
      <Button reg trailing="flaticon-login" to="/login"
        >Login / Register</Button
      >
    </div>
  </div>
</template>

<script>
import Dropdown from "./components/Dropdown.vue";
import Button from "@/components/ui/Button.vue";

import Fuse from "fuse.js";

export default {
  components: {
    Dropdown,
    Button
  },
  props: {
    isMobile: Boolean
  },
  data() {
    return {
      searchValue: "",
      fuse: null
    };
  },
  created() {
    const options = {
      keys: [
        {
          name: "name",
          weight: 0.5
        },
        {
          name: "meta.keywords",
          weight: 0.99
        }
      ],
      distance: 50,
      threshold: 0.4,
      ignoreLocation: true
    };

    var routes = this.getAllRoutes();
    this.fuse = new Fuse(routes, options);
  },
  methods: {
    getAllRoutes() {
      var routes = this.$router.options.routes;
      var children = routes
        .filter(el => el.children)
        .map(el => {
          for (var i = 0; i < el.children.length; i++) {
            if (el.path == el.children[i].path.slice(0, el.path.length)) {
              continue;
            }
            el.children[i].path = el.path + "/" + el.children[i].path;
          }
          return el.children;
        })
        .flat();
      return routes.concat(children);
    },
    search(value) {
      if (value !== "") {
        var result = this.fuse.search(value);
        return result.map(el => el.item).filter(el => !el.meta.noSearch);
      } else {
        return [];
      }
    }
  },
  computed: {
    results: function() {
      return this.search(this.searchValue);
    }
  }
};
</script>

<style scoped>
#searchbar {
  max-width: 15rem;
}

.fadeIn-enter,
.fadeIn-leave-to {
  opacity: 0;
}

#searchbar:not(:focus-within) #results {
  display: none;
}
</style>
