<template>
  <div id="sidenav" v-if="isMobile" class="z-10 text-base">
    <div
      id="burger"
      @click="toggleSidenav()"
      :class="{ active: sidenavActive }"
      class="absolute top-6 left-6 w-8 h-8 py-0.5 px-1 z-30"
    >
      <div
        id="line1"
        class="w-6 h-1 mx-0 my-1 transition-all rounded-sm bg-primary-light"
        :class="{ 'bg-primary': sidenavActive }"
      ></div>
      <div
        id="line2"
        class="w-6 h-1 mx-0 my-1 transition-all rounded-sm bg-primary-light"
        :class="{ 'bg-primary': sidenavActive }"
      ></div>
      <div
        id="line3"
        class="w-6 h-1 mx-0 my-1 transition-all rounded-sm bg-primary-light"
        :class="{ 'bg-primary': sidenavActive }"
      ></div>
    </div>
    <transition name="slide-in">
      <div
        id="sidenav-container"
        v-show="sidenavActive"
        class="fixed top-0 left-0 z-20 flex flex-col h-screen bg-neutral-light w-72"
      >
        <div id="sidenav-header" class="h-20 text-center">
          <router-link to="/" @click.native="closeSidenav()">
            <img
              src="@/assets/RMRGreen_Logo.png"
              alt="RMR Logo"
              draggable="false"
              class="mx-auto my-2 h-16"
            />
          </router-link>
        </div>
        <div
          id="sidenav-links"
          ref="sidenavLinks"
          class="overflow-y-scroll border-0 border-t border-solid border-neutral"
        >
          <router-link
            @click.native="closeSidenav()"
            to="/"
            class="block p-4 pl-6"
            >Home</router-link
          >
          <Dropdown heading="Participants">
            <router-link
              class="p-4 pl-12 bg-primary"
              @click.native="closeSidenav()"
              to="/login/participant"
            >
              Participant Login
            </router-link>
            <router-link
              class="p-4 pl-12 bg-primary"
              @click.native="closeSidenav()"
              to="/services/cobra"
            >
              COBRA
            </router-link>
            <router-link
              class="p-4 pl-12 bg-primary"
              @click.native="closeSidenav()"
              to="/forms"
            >
              Forms
            </router-link>
            <router-link
              class="p-4 pl-12 bg-primary"
              @click.native="closeSidenav()"
              to="/whats-covered"
            >
              What's Covered
            </router-link>
            <router-link
              class="p-4 pl-12 bg-primary"
              @click.native="closeSidenav()"
              to="/support"
            >
              Support & FAQ
            </router-link>
            <router-link
              class="p-4 pl-12 bg-primary"
              @click.native="closeSidenav()"
              to="/services"
            >
              Our Services
            </router-link>
            <router-link
              class="p-4 pl-12 bg-primary"
              @click.native="closeSidenav()"
              to="/calculators"
            >
              Calculators
            </router-link>
            <a
              class="p-4 pl-12 bg-primary"
              href="https://fsastore.com/rockymountainreserve.html?AFID=490026&GroupName=TPA&CID=437559&utm_source=Rocky+Mountain+Reserve&utm_medium=tpa+public+link+nav+CLP&utm_campaign=TPA+Partner"
            >
              FSA Store
            </a>
            <!-- <a class="p-4 pl-12 bg-primary" href="https://rmr.directfsa.com/">
              <img
                src="@/assets/Direct FSA Logo - White.png"
                alt="Direct FSA Logo"
              />
            </a> -->
          </Dropdown>
          <Dropdown heading="Employers/Brokers">
            <router-link
              class="p-4 pl-12 bg-primary"
              @click.native="closeSidenav()"
              to="/login/employer"
            >
              Employer Login
            </router-link>
            <router-link
              class="p-4 pl-12 bg-primary"
              @click.native="closeSidenav()"
              to="/employer-resources"
            >
              Employer Resources
            </router-link>
            <router-link
              class="p-4 pl-12 bg-primary"
              @click.native="closeSidenav()"
              to="/services"
            >
              Our Services
            </router-link>
            <router-link
              class="p-4 pl-12 bg-primary"
              @click.native="closeSidenav()"
              to="/support/categories/360002944833"
            >
              Support & FAQ
            </router-link>
            <router-link
              class="p-4 pl-12 bg-primary"
              @click.native="closeSidenav()"
              to="/employer-training"
            >
              Employer Training
            </router-link>
          </Dropdown>
        </div>
        <div class="mx-auto mt-4 mb-8 text-center">
          <Button
            reg
            trailing="flaticon-login"
            to="/login"
            @click.native="closeSidenav()"
          >
            Login / Register
          </Button>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div
        id="sidenav-overlay"
        v-if="sidenavActive && isMobile"
        @click="closeSidenav()"
        class="fixed top-0 left-0 z-0 w-screen h-screen bg-black bg-opacity-50"
      ></div>
    </transition>
  </div>
</template>

<script>
import Dropdown from "./components/Dropdown.vue";
import Button from "@/components/ui/Button.vue";

export default {
  components: {
    Dropdown,
    Button
  },
  data() {
    return {
      sidenavActive: false,
      activeDropdown: 0
    };
  },
  props: {
    isMobile: Boolean
  },
  methods: {
    toggleSidenav() {
      if (this.sidenavActive === false) {
        this.openSidenav();
      } else {
        this.closeSidenav();
      }
    },
    openSidenav() {
      var body = document.body;
      this.sidenavActive = true;
      body.style.overflow = "hidden";
    },
    closeSidenav() {
      var body = document.body;
      this.sidenavActive = false;
      body.style.removeProperty("overflow");
    }
  }
};
</script>

<style scoped>
#burger.active #line1 {
  transform: rotate(45deg) translate(5px, 6px);
}

#burger.active #line2 {
  opacity: 0;
}

#burger.active #line3 {
  transform: rotate(-45deg) translate(5px, -6px);
}

.slide-in-enter-active,
.slide-in-leave-active {
  transition: all 0.5s ease;
}

.slide-in-enter,
.slide-in-leave-to {
  transform: translateX(-100%);
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
