import Vue from "vue";
import Vuex from "vuex";
import zendesk from "./modules/zendesk";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    zendesk
  }
});
