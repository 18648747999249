var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.component,{tag:"component",staticClass:"button",class:{
    small: _vm.sml,
    regular: _vm.reg,
    large: _vm.lrg,
    light: _vm.light,
    iconLarge: _vm.largeIcons,
    slideFromLeft: _vm.slideTextLeft,
    slideFromRight: _vm.slideTextRight
  },attrs:{"href":_vm.href,"to":_vm.to,"download":_vm.download ? _vm.href.substring(1) : false,"target":_vm.newTab ? '_blank' : false},on:{"click":function($event){return _vm.clickButton()},"mouseover":function($event){return _vm.mouseoverButton()},"mouseleave":function($event){return _vm.mouseleaveButton()}}},[_c('div',{staticClass:"text-wrapper"},[(_vm.slideTextLeft)?_c('span',{staticClass:"hidden-text left"},[_vm._v(" "+_vm._s(_vm.slideTextLeft)+" ")]):_vm._e(),_c('span',{staticClass:"visible-text"},[(_vm.leading)?_c('span',{staticClass:"icon leading",class:_vm.leading}):_vm._e(),_vm._t("default"),(_vm.trailing)?_c('span',{staticClass:"icon trailing",class:_vm.trailing}):_vm._e()],2),(_vm.slideTextRight)?_c('span',{staticClass:"hidden-text right"},[_vm._v(" "+_vm._s(_vm.slideTextRight)+" ")]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.showTooltip && _vm.tooltipClick)?_c('span',{attrs:{"id":"tooltip"}},[_vm._v(" "+_vm._s(_vm.tooltipClick)+" ")]):(_vm.showTooltip && _vm.tooltipHover)?_c('span',{attrs:{"id":"tooltip"}},[_vm._v(" "+_vm._s(_vm.tooltipHover)+" ")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }