<template>
  <component
    :is="component"
    :href="href"
    :to="to"
    :download="download ? href.substring(1) : false"
    :target="newTab ? '_blank' : false"
    class="button"
    :class="{
      small: sml,
      regular: reg,
      large: lrg,
      light: light,
      iconLarge: largeIcons,
      slideFromLeft: slideTextLeft,
      slideFromRight: slideTextRight
    }"
    @click="clickButton()"
    @mouseover="mouseoverButton()"
    @mouseleave="mouseleaveButton()"
  >
    <div class="text-wrapper">
      <span v-if="slideTextLeft" class="hidden-text left">
        {{ slideTextLeft }}
      </span>
      <span class="visible-text">
        <span v-if="leading" class="icon leading" :class="leading" />
        <slot />
        <span v-if="trailing" class="icon trailing" :class="trailing" />
      </span>
      <span v-if="slideTextRight" class="hidden-text right">
        {{ slideTextRight }}
      </span>
    </div>
    <transition name="fade">
      <span v-if="showTooltip && tooltipClick" id="tooltip">
        {{ tooltipClick }}
      </span>
      <span v-else-if="showTooltip && tooltipHover" id="tooltip">
        {{ tooltipHover }}
      </span>
    </transition>
  </component>
</template>

<script>
export default {
  data() {
    return {
      showTooltip: false
    };
  },
  props: {
    sml: Boolean,
    reg: Boolean,
    lrg: Boolean,
    light: Boolean,
    leading: String,
    trailing: String,
    largeIcons: Boolean,
    href: String,
    to: String,
    download: Boolean,
    newTab: Boolean,
    slideTextLeft: String,
    slideTextRight: String,
    tooltipHover: String,
    tooltipClick: String
  },
  computed: {
    component() {
      if (this.href) {
        return "a";
      } else if (this.to) {
        return "router-link";
      } else {
        return "div";
      }
    }
  },
  methods: {
    clickButton() {
      this.showTooltip = true;
      setTimeout(() => {
        this.showTooltip = false;
      }, 1000);
      this.$emit("click-button");
    },
    mouseoverButton() {
      if (this.tooltipHover) {
        this.showTooltip = true;
      } else {
        this.showTooltip = false;
      }
    },
    mouseleaveButton() {
      this.showTooltip = false;
    }
  }
};
</script>

<style scoped>
.button {
  position: relative;
  display: inline-block;
  border-radius: 0.25rem;
  background-color: theme("colors.primary.light");
  color: white;
  text-align: center;
  transition: all 0.15s;
  cursor: pointer;
  user-select: none;
  overflow: visible;
}

.button:hover {
  background-color: theme("colors.primary.mid");
}

.button:active {
  background-color: theme("colors.primary.DEFAULT");
}

.button.small {
  border-radius: 0.25rem;
  box-shadow: 0 3px theme("colors.primary.DEFAULT");
}

.button.small:active {
  transform: translateY(3px);
  box-shadow: 0 0 theme("colors.primary.DEFAULT");
}

.button.small.iconLarge .text-wrapper .icon {
  font-size: 1.5rem;
  line-height: 1rem;
}

.button.small .text-wrapper .hidden-text,
.button.small .text-wrapper .visible-text {
  padding: 0.5rem;
}

.button.regular {
  min-height: 3rem;
  box-shadow: 0 4px theme("colors.primary.DEFAULT");
}

.button.regular:active {
  transform: translateY(4px);
  box-shadow: 0 0 theme("colors.primary.DEFAULT");
}

.button.regular.iconLarge .text-wrapper .icon {
  font-size: 1.5rem;
  line-height: 1rem;
}

.button.regular .text-wrapper .hidden-text,
.button.regular .text-wrapper .visible-text {
  padding: 0.5rem 1rem;
  line-height: 2rem;
}

.button.large {
  border-radius: 0.5rem;
  box-shadow: 0 5px theme("colors.primary.DEFAULT");
}

.button.large:active {
  transform: translateY(5px);
  box-shadow: 0 0 theme("colors.primary.DEFAULT");
}

.button.large.iconLarge .text-wrapper .icon {
  font-size: 2rem;
  line-height: 1.5rem;
}

.button.large .text-wrapper .hidden-text,
.button.large .text-wrapper .visible-text {
  padding: 1.5rem;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.button.light {
  background-color: theme("colors.neutral.light");
  box-shadow: 0 4px theme("colors.neutral.dark");
  color: theme("colors.neutral.900");
}

.button.light:hover {
  background-color: theme("colors.neutral.DEFAULT");
}

.button.light:active {
  background-color: theme("colors.neutral.dark");
  box-shadow: 0 0 theme("colors.neutral.dark");
}

.icon {
  display: inline-block;
}

.icon.leading {
  margin-right: 0.5rem;
}

.icon.trailing {
  margin-left: 0.5rem;
}

.text-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.hidden-text,
.visible-text {
  display: inline-block;
  width: 100%;
  transition: all 0.25s ease;
}

.hidden-text {
  position: absolute;
  top: 0;
}

.hidden-text.left {
  right: 100%;
}

.hidden-text.right {
  left: 100%;
}

.slideFromLeft:hover .text-wrapper .hidden-text,
.slideFromLeft:hover .text-wrapper .visible-text {
  transform: translateX(100%);
}

.slideFromRight:hover .text-wrapper .hidden-text,
.slideFromRight:hover .text-wrapper .visible-text {
  transform: translateX(-100%);
}

#tooltip {
  background-color: theme("colors.neutral.light");
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid theme("colors.neutral.DEFAULT");
  color: theme("colors.neutral.900");
  position: absolute;
  bottom: 3rem;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}

#tooltip::before,
#tooltip::after {
  position: absolute;
  top: 100%;
  left: 50%;
  content: "";
}

#tooltip::before {
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: theme("colors.neutral.DEFAULT") transparent transparent
    transparent;
}

#tooltip::after {
  margin-left: -4px;
  border-width: 4px;
  border-style: solid;
  border-color: theme("colors.neutral.light") transparent transparent
    transparent;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
