<template>
  <div class="mt-24 md:mt-32">
    <transition name="fade" mode="out-in">
      <div v-bind:key="index">
        <p class="leading-normal">{{ reviews[index].quote }}</p>
        <h2 class="m-4 text-3xl font-semibold text-primary">
          {{ reviews[index].company }}
        </h2>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: 0
    };
  },
  props: {
    reviews: Array
  },
  created: function() {
    setInterval(() => {
      if (this.index === this.reviews.length - 1) {
        this.index = 0;
      } else {
        this.index++;
      }
    }, 7500);
  },
  computed: {
    length: function() {
      return this.reviews.length;
    }
  }
};
</script>

<style scoped>
p {
  font-size: 1.5rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
