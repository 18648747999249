<template>
  <div
    class="dropdown inline-block cursor-pointer select-none"
    @mouseover="showDropdown = true"
    @mouseleave="showDropdown = false"
  >
    <span class="heading inline-block p-4 my-4 mx-0 h-12 leading-4"
      >{{ heading }}
      <span class="down-arrow flaticon-down-arrow ml-1 inline-block text-xs" />
    </span>

    <transition name="slide-down">
      <div
        v-show="showDropdown"
        class="absolute bg-neutral-light rounded-b-lg dropdown-shadow origin-top overflow-hidden"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showDropdown: false
    };
  },
  props: {
    heading: String
  }
};
</script>

<style scoped>
.dropdown-shadow {
  -webkit-box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.25);
  -mox-box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.25);
}

.slide-down-enter-active {
  transition: all 0.2s ease;
}

.slide-down-leave-active {
  transition: all 0.15s ease;
}

.slide-down-enter,
.slide-down-leave-to {
  transform: scaleY(0);
}
</style>
