<template>
  <div class="dropdown" @click="showDropdown = !showDropdown">
    <div class="block p-4 pl-6 leading-4 heading">
      {{ heading }}
      <span
        class="inline-block ml-1 text-xs transition-all down-arrow flaticon-down-arrow"
        :class="{ 'rotate-arrow': showDropdown }"
      />
    </div>
    <transition
      v-on:before-enter="beforeEnter"
      v-on:after-enter="afterEnter"
      v-on:leave="leave"
      v-on:afterLeave="afterLeave"
    >
      <div
        v-show="showDropdown"
        class="flex flex-col overflow-y-hidden text-white origin-top"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showDropdown: false
    };
  },
  props: {
    heading: String,
    activeDropdown: Number,
    dropdownKey: Number
  },
  methods: {
    beforeEnter(el) {
      var clone = el.cloneNode(true);
      clone.style.width = el.style.width;
      clone.style.visibility = "hidden";
      clone.style.removeProperty("display");

      var h;
      el.parentNode.appendChild(clone);
      h = clone.clientHeight;
      clone.remove();

      var duration = (Math.floor(parseInt(h) / 100) + 1) * 0.15;

      el.style.transition = `all ${duration}s ease`;
      el.style.height = "0px";
      setTimeout(() => (el.style.height = h + "px"), 1);
    },
    afterEnter(el) {
      el.style.removeProperty("transition");
      el.style.removeProperty("height");
    },
    leave(el) {
      var duration = (Math.floor(parseInt(el.offsetHeight) / 100) + 1) * 0.05;

      el.style.transition = `all ${duration}s ease`;
      el.style.height = el.offsetHeight + "px";
      setTimeout(() => (el.style.height = "0px"), 1);
    },
    afterLeave(el) {
      el.style.removeProperty("transition");
      el.style.removeProperty("height");
    }
  }
};
</script>

<style scoped>
.rotate-arrow {
  transform: rotate(180deg);
}
</style>
