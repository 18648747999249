import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Zendesk from "@dansmaculotte/vue-zendesk";
import "@/assets/styles/index.css";
import "@/assets/styles/flaticon.css";
import VueSmoothScroll from "vue2-smooth-scroll";
import VueCoreVideoPlayer from "vue-core-video-player";
import VueGtag from "vue-gtag";
import VueMeta from "vue-meta";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";

Vue.use(VueMeta);

Vue.use(
  VueGtag,
  {
    config: { id: "G-S8Y804051C" }
  },
  router
);
Vue.use(VuePlyr, {
  plyr: {}
});
Vue.use(VueSmoothScroll);
Vue.use(VueCoreVideoPlayer);
Vue.use(Zendesk, {
  key: "a95486a9-0bcf-4bda-99ac-f81566ab1958",
  disabled: true,
  hideOnLoad: true,
  settings: {
    webWidget: {
      launcher: {
        label: {
          "*": "Chat With Us"
        }
      },
      position: { horizontal: "right", vertical: "bottom" },
      offset: {
        horizontal: "16px",
        vertical: "16px"
      },
      color: {
        launcher: "#2e8b57",
        launcherText: "#ffffff"
      }
    }
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
