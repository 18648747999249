<template>
  <div id="app" class="w-full flex flex-col text-lg antialiased">
    <Navbar v-if="$route.path.split('/')[1].toLowerCase() != 'upgrade'" />
    <plan-limits-banner
      v-if="$route.path.split('/')[1].toLowerCase() != 'upgrade'"
      v-on:alert-bar-closed="alertBarHeight = 0"
    />
    <Breadcrumb
      v-show="this.$route.name !== 'home'"
      :style="{ top: breadcrumbTop + 'px' }"
    />
    <router-view></router-view>
    <Footer v-if="$route.path.split('/')[1].toLowerCase() != 'upgrade'" />
  </div>
</template>

<script>
import { mapActions } from "vuex";

import Navbar from "./components/global/navbar/Navbar.vue";
import Breadcrumb from "./components/global/Breadcrumb.vue";
import Footer from "./components/global/footer/Footer.vue";
import PlanLimitsBanner from "./components/global/PlanLimitsBanner.vue";

export default {
  components: {
    Navbar: Navbar,
    Breadcrumb: Breadcrumb,
    Footer: Footer,
    PlanLimitsBanner: PlanLimitsBanner
  },
  data() {
    return {
      navbarHeight: 0,
      alertBarHeight: 0
    };
  },
  created() {
    this.fetchZendeskData();
  },
  mounted() {
    this.navbarHeight = document.getElementById("topnav").offsetHeight;
    this.alertBarHeight = this.getAlertBarHeight();
    window.addEventListener("resize", () => {
      this.alertBarHeight = this.getAlertBarHeight();
    });
  },
  computed: {
    breadcrumbTop: function() {
      if (this.alertBarHeight === 0) {
        return this.navbarHeight;
      } else {
        return this.navbarHeight + this.alertBarHeight;
      }
    }
  },
  methods: {
    ...mapActions(["fetchZendeskData"]),
    getAlertBarHeight() {
      if (document.getElementById("plan-limits-banner") !== null) {
        return document.getElementById("plan-limits-banner").offsetHeight;
      } else {
        return 0;
      }
    }
  }
};
</script>
