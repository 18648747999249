<template>
  <div
    id="breadcrumb-wrapper"
    class="absolute w-full h-16 p-0 m-0 transition-all duration-500 top-20"
  >
    <div
      id="breadcrumb"
      :class="{ 'text-white': this.breadcrumbLight }"
      class="w-auto pt-4 pb-8 mx-4 my-0 text-sm lg:max-w-6xl xl:mx-auto"
    >
      <ul v-if="screenWidth > 425" class="p-0 m-0">
        <li
          v-for="(breadcrumb, idx) in breadcrumbList"
          :key="idx"
          class="inline-block mb-1"
        >
          <span
            @click="routeTo(idx)"
            :class="{
              'cursor-pointer hover:text-primary-light hover:underline': !!breadcrumb.link
            }"
          >
            {{ breadcrumb.name }}</span
          >
          <span
            v-if="breadcrumb.link"
            class="inline-block mx-1 my-0 text-xs flaticon-right-arrow"
          />
        </li>
        <li v-if="articleId && articleById" class="inline-block mb-1">
          <span>{{ articleById.name }}</span>
        </li>
        <li v-if="sectionId && sectionById" class="inline-block mb-1">
          <span>{{ sectionById.name }}</span>
        </li>
        <li v-if="categoryId && categoryById" class="inline-block mb-1">
          <span>{{ categoryById.name }}</span>
        </li>
      </ul>
      <div v-else-if="breadcrumbList.length >= 2">
        <span class="inline-block mr-2 text-xs flaticon-left-arrow" />
        <span
          @click="routeTo(breadcrumbList.length - 2)"
          :class="{
            'cursor-pointer hover:text-primary-light hover:underline': !!breadcrumbList[
              breadcrumbList.length - 2
            ].link
          }"
          >Go back to {{ breadcrumbList[breadcrumbList.length - 2].name }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  data() {
    return {
      breadcrumbList: [],
      breadcrumbLight: false,
      articleId: parseInt(this.$route.params.articleId),
      sectionId: parseInt(this.$route.params.sectionId),
      categoryId: parseInt(this.$route.params.categoryId),
      screenWidth: window.innerWidth
    };
  },
  mounted() {
    this.updateList();
    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });
  },
  watch: {
    $route() {
      this.updateList();

      this.breadcrumbLight = this.$route.meta.breadcrumbLight;
      if (this.$route.params.articleId) {
        this.articleId = parseInt(this.$route.params.articleId);
        this.sectionId = undefined;
        this.categoryId = undefined;
      } else if (this.$route.params.sectionId) {
        this.articleId = undefined;
        this.sectionId = parseInt(this.$route.params.sectionId);
        this.categoryId = undefined;
      } else if (this.$route.params.categoryId) {
        this.articleId = undefined;
        this.sectionId = undefined;
        this.categoryId = parseInt(this.$route.params.categoryId);
      } else {
        this.articleId = undefined;
        this.sectionId = undefined;
        this.categoryId = undefined;
      }
    }
  },
  methods: {
    routeTo(pRouteTo) {
      if (this.breadcrumbList[pRouteTo].link) {
        this.$router.push(this.breadcrumbList[pRouteTo].link);
      }
    },
    updateList() {
      if (this.$route.meta.breadcrumb) {
        this.breadcrumbList = this.$route.meta.breadcrumb;
      } else {
        this.breadcrumbList = [];
      }
      if (this.$route.meta.breadcrumbLight) {
        this.breadcrumbLight = true;
      } else {
        this.breadcrumbLight = false;
      }
    }
  },
  computed: {
    articleById() {
      return this.$store.getters.articleById(this.articleId);
    },
    sectionById() {
      return this.$store.getters.sectionById(this.sectionId);
    },
    categoryById() {
      return this.$store.getters.categoryById(this.categoryId);
    }
  }
};
</script>

<style scoped>
.min-auto-margin {
  margin-left: max(1rem, auto);
  margin-right: max(1rem, auto);
}
</style>
