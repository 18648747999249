<template>
  <base-view id="home">
    <view-section class="bg-white">
      <div class="relative">
        <div class="mx-auto max-w-7xl">
          <div class="relative shadow-xl sm:overflow-hidden sm:rounded-2xl">
            <div class="absolute inset-0">
              <img
                class="h-full w-full object-cover"
                src="@/assets/bg.jpg"
                alt="People working on laptops"
              />
              <div class="absolute bg-indigo-900 mix-blend-multiply" />
            </div>
            <div class="relative top-32 px-6 py-16 sm:py-24 lg:px-8 lg:py-24">
              <h1
                class="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl"
              >
                <span class="block text-white font-bold"
                  >Start getting the benefit out of your
                </span>
                <span class="block text-blue-200 mt-4">Benefits Plan</span>
              </h1>
              <div class="flex justify-center items-center mt-12 pb-4">
                <p class="text-center text-2xl text-white mr-4">
                  Download our App - RMR Benefits
                </p>
                <img class="w-12 rounded" src="@/assets/rmrAppStoreLogo.png" />
              </div>
              <div
                class="mx-auto  max-w-full sm:flex sm:max-w-none sm:justify-between"
              >
                <div
                  class="flex items-center pb-24 md:pb-16 w-full justify-evenly"
                >
                  <div class="flex w-1/2 justify-evenly items-center">
                    <div class="bg-white p-4 hidden md:inline-block">
                      <img src="@/assets/appleQR.png" class="h-24" />
                    </div>
                    <a
                      href="https://apps.apple.com/us/app/rmr-benefits/id6479498415"
                      ><img class="h-12 w-auto" src="@/assets/appStoreBlack.svg"
                    /></a>
                  </div>
                  <div class="flex w-1/2 justify-evenly items-center">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.rockymountainreserve.rmr_app&hl=en_US&gl=US"
                      ><img
                        class="h-12 w-auto"
                        src="@/assets/googlePlayBlack.png"
                    /></a>
                    <div class="bg-white p-4 hidden md:inline-block">
                      <img src="@/assets/googleQR.png" class="h-24" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </view-section>
    <!-- <view-section class="pt-16 pb-56 text-center text-white md:pb-48">
      <p
        class="py-8 m-0 text-5xl leading-normal md:py-12 md:text-7xl md:leading-normal"
      >
        Start getting the benefit out of your Benefits Plan
      </p>

      <Button lrg trailing="flaticon-login" to="/login">
        Login / Register
      </Button>
    </view-section> -->
    <view-section class="py-0 text-center bg-neutral-light px-auto">
      <h1 class="mb-12 text-5xl font-semibold">Service Comes First</h1>
      <div class="flex flex-col justify-between pt-8 md:flex-row">
        <div class="w-full md:w-80">
          <div class="inline-block text-primary-light text-9xl">
            <span class="flaticon-management" />
          </div>
          <p class="mx-0 my-4 text-4xl font-bold text-primary-light">71+</p>
          <p class="text-2xl font-semibold">
            Years of combined relationship manager experience
          </p>
        </div>
        <div class="w-full md:w-80">
          <div class="inline-block text-primary-light text-9xl">
            <span class="flaticon-chat" />
          </div>
          <p class="mx-0 my-4 text-4xl font-bold text-primary-light">93%</p>
          <p class="text-2xl font-semibold">
            Client Services Center satisfaction rate by participants
          </p>
        </div>
        <div class="w-full md:w-80">
          <div class="inline-block text-primary-light text-9xl">
            <span class="flaticon-contract" />
          </div>
          <p class="mx-0 my-4 text-4xl font-bold text-primary-light">207,000</p>
          <p class="text-2xl font-semibold">
            Combined COBRA and cafeteria plans administered in 2020
          </p>
        </div>
      </div>
      <Carousel :reviews="reviews" />
    </view-section>
    <view-section class="text-center bg-white">
      <h1 class="mb-12 text-5xl font-semibold">What We Do</h1>
      <div
        class="flex flex-col flex-wrap items-center justify-around md:flex-row"
      >
        <router-link
          class="flex flex-row items-center w-full p-4 mb-4 text-2xl text-left transition-all rounded shadow bg-neutral-lighter sm:w-96 sm:m-4 group hover:text-white hover:bg-primary-light active:bg-primary"
          to="/services/fsa"
        >
          <span
            class="inline-block mr-4 text-5xl text-primary-light group-hover:text-white flaticon-medical"
          />Flexible Spending
        </router-link>
        <router-link
          class="flex flex-row items-center w-full p-4 mb-4 text-2xl text-left transition-all rounded shadow bg-neutral-lighter sm:w-96 sm:m-4 group hover:text-white hover:bg-primary-light active:bg-primary"
          to="/services/hsa"
        >
          <span
            class="inline-block mr-4 text-5xl text-primary-light group-hover:text-white flaticon-growth"
          />Health Savings
        </router-link>
        <router-link
          class="flex flex-row items-center w-full p-4 mb-4 text-2xl text-left transition-all rounded shadow bg-neutral-lighter sm:w-96 sm:m-4 group hover:text-white hover:bg-primary-light active:bg-primary"
          to="/services/hra"
        >
          <span
            class="inline-block mr-4 text-5xl text-primary-light group-hover:text-white flaticon-refund"
          />Health Reimbursement
        </router-link>
        <router-link
          class="flex flex-row items-center w-full p-4 mb-4 text-2xl text-left transition-all rounded shadow bg-neutral-lighter sm:w-96 sm:m-4 group hover:text-white hover:bg-primary-light active:bg-primary"
          to="/services/transit"
        >
          <span
            class="inline-block mr-4 text-5xl text-primary-light group-hover:text-white flaticon-bus-1"
          />Transit/Parking
        </router-link>
        <router-link
          class="flex flex-row items-center w-full p-4 mb-4 text-2xl text-left transition-all rounded shadow bg-neutral-lighter sm:w-96 sm:m-4 group hover:text-white hover:bg-primary-light active:bg-primary"
          to="/services/cobra"
        >
          <span
            class="inline-block mr-4 text-5xl text-primary-light group-hover:text-white flaticon-files-and-folders"
          />Cobra Administration
        </router-link>
        <router-link
          class="flex flex-row items-center w-full p-4 mb-4 text-2xl text-left transition-all rounded shadow bg-neutral-lighter sm:w-96 sm:m-4 group hover:text-white hover:bg-primary-light active:bg-primary"
          to="/services/wellness"
        >
          <span
            class="inline-block mr-4 text-5xl text-primary-light group-hover:text-white flaticon-apple"
          />Wellness Accounts
        </router-link>
        <router-link
          class="flex flex-row items-center w-full p-4 mb-4 text-2xl text-left transition-all rounded shadow bg-neutral-lighter sm:w-96 sm:m-4 group hover:text-white hover:bg-primary-light active:bg-primary"
          to="/services/tuition"
        >
          <span
            class="inline-block mr-4 text-5xl text-primary-light group-hover:text-white flaticon-cap"
          />Tuition Reimbursement
        </router-link>
        <router-link
          class="flex flex-row items-center w-full p-4 mb-4 text-2xl text-left transition-all rounded shadow bg-neutral-lighter sm:w-96 sm:m-4 group hover:text-white hover:bg-primary-light active:bg-primary"
          to="/services/dca"
        >
          <span
            class="inline-block mr-4 text-5xl text-primary-light group-hover:text-white flaticon-lifetime"
          />Dependent Care Assistance
        </router-link>
      </div>
    </view-section>
    <!-- <view-section class="text-center bg-neutral-light">
      <div class="my-16"></div>
      <h1 class="mb-12 text-5xl font-semibold">Ready To Get Started?</h1>
      <Button lrg to="/quote">
        Request a Quote
      </Button>
    </view-section> -->
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import ViewSection from "@/components/layout/ViewSection.vue";
import Button from "@/components/ui/Button.vue";

import Carousel from "@/views/Home/components/Carousel.vue";

export default {
  name: "home",
  components: {
    BaseView,
    ViewSection,
    Carousel
  },
  data() {
    return {
      reviews: [
        {
          company: "INSCRIPTA",
          quote:
            '"Rocky Mountain Reserve has been a great partner for our FSA, HSA and COBRA administration needs. Whenever a question arises, the team has been extremely responsive. "'
        },
        {
          company: "Kong Company",
          quote:
            '"Having a partner like Rocky Mountain Reserve to offset managing COBRA, HRA, and FSA is like having a magic wand. You can count on RMR to handle the administration seamlessly."'
        },
        {
          company: "Cosmic AES",
          quote:
            '"Rocky Mountain Reserve provides quality service by employing knowledgeable and hardworking staff. They are expeditious in their resolution of claims and efficient when resolving issues that rarely occur. Cosmic AES is proud to be partnered with RMR for 7 years straight"'
        },
        {
          company: "Moxie SoZo",
          quote:
            '"Our goal with selecting an FSA plan administrator was to find a company that efficiently provides an FSA plan without any hiccups — Rocky Mountain Reserve goes above & beyond this goal by providing a great plan and AMAZING customer service."'
        },
        {
          company: "Air Force Academy Athletics",
          quote:
            '"Our employees and I (as the plan administrator) always appreciate the friendly service and extremely fast turnaround times provided by Rocky Mountain Reserve!"'
        },
        {
          company: "ICON Fitness",
          quote:
            '"RMR is great to work with. They\'re always on the ball and they do all that they can, every time, to help our business run smoothly!"'
        }
      ]
    };
  }
};
</script>

<style scoped>
#home {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("../../assets/bg.jpg");
  background-position-x: center;
}
</style>
